import {FontAwesomeIcon} from "@fortawesome/vue-fontawesome";

import analysisCard from "../ToolsCard.vue";

import moment from "moment";
import Button from "@/components/button";
import Input from "@/components/input";
import Table from "@/components/table";
import Tab from "@/components/tab";
import NeoTabsSwitch from "@/components/tabs-switch";
import Loader from "@/components/loader";
import Dropdown from "@/components/dropdown";
import Pagination from "@/components/pagination";
import axios from "../../../../axios/osint.js";
import mainAxios from "@/axios";
import DatePicker from "vue2-datepicker";
import VueMultiselect from "@/components/vue-multiselect";
// import "vue-multiselect/dist/vue-multiselect.min.css";
import "@/assets/css/multiselect.scss";
import runAllQueries from "@/mixins/runAllQueries";
import {DateTime} from "luxon";
import {mapActions, mapGetters, mapMutations} from "vuex";
import ModalConfirm from "@/components/modal-confirm";
import NeoResultList from "@/components/result-list";
import NeoResultExpanded from "@/components/result-expanded";
import {getMappingValue} from "@/utils/functions";
import {NEWS_TOOL_MAPPING} from "@/utils/constants";
import {EventBus} from "@/main.js";
import KeywordsSelectorTool from "@/components/keywords-selector-tool";
import {monitoringSearchData, monitoringHistoryData} from "@/utils/monitoringTools.js";
import toolsHelper from "@/mixins/toolsHelper";
import SaasAxios from "@/axios";
import {cloneDeep, get, isEqual, uniqBy} from "lodash";
import ExpandFilter from "./../ExpandFilter.vue";
import FilterModal from "./../FilterModal.vue";
import FilterComponent from "@/components/filter-component";
import {debounce} from "lodash";
import {FEATURES} from "@/utils/constants";
import {interval} from "rxjs";
const generateFiltersWrapperModel = (title, src_name, value, filters, tagClass) => ({
    title,
    src_name,
    value,
    filters,
    tagClass,
    isChecked: false,
    isModalVisible: false,
    disabled: false,
});

export default {
    name: "neo-news",
    /**
     * @mixin
     * @name toolsHelper - mixin to control tools & monitoring actions
     */
    mixins: [toolsHelper, runAllQueries],
    /**
     * @components - external components used in the component
     */
    components: {
        "neo-input": Input,
        "neo-button": Button,
        "neo-table": Table,
        "neo-tab": Tab,
        "neo-tabs-switch": NeoTabsSwitch,
        "neo-pagination": Pagination,
        "font-awesome-icon": FontAwesomeIcon,
        "neo-loader": Loader,
        "neo-dropdown": Dropdown,
        "analysis-card": analysisCard,
        "neo-datepicker": DatePicker,
        "keywords-selector-tool": KeywordsSelectorTool,
        "modal-confirm": ModalConfirm,
        VueMultiselect,
        NeoResultList,
        NeoResultExpanded,
        ExpandFilter,
        FilterModal,
        FilterComponent,
    },
    props: {
        isQueryPanelExpanded: {
            type: Boolean,
            default: false,
        },
        toolSearchQueries: Array,
        monitoring: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            interval: null,
            analysisCards: [],
            totalFilteredPages: 1,
            count: 0,
            filtersOptions: [
                {
                    name: "saved",
                    title: "Saved",
                    searchedTerm: "",
                    svg: require("@/assets/icons/filter-component/people.svg"),
                    data: [
                        {name: "All", checked: true, key: "all"},
                        {name: "Saved", checked: false, key: "saved"},
                        {name: "Not Saved", checked: false, key: "notSaved"},
                    ],
                    dataFetched: true,
                    page: 1,
                    limit: 6,
                    selectAll: false,
                },
                {
                    name: "monitoring",
                    title: "Monitoring",
                    searchedTerm: "",
                    svg: require("@/assets/icons/filter-component/people.svg"),
                    data: [
                        {name: "All", checked: true, key: "all"},
                        {name: "Monitoring", checked: false, key: "monitoring"},
                        {name: "Not Monitoring", checked: false, key: "notMonitoring"},
                    ],
                    dataFetched: true,
                    page: 1,
                    limit: 6,
                    selectAll: false,
                },
                {name: "sources", title: "Sources", searchedTerm: "", svg: require("@/assets/icons/filter-component/people.svg"), data: [], dataFetched: true, page: 1, limit: 6, selectAll: false},
                {name: "person", title: "Persons", searchedTerm: "", data: [], svg: require("@/assets/icons/filter-component/people.svg"), dataFetched: false, page: 1, limit: 6, selectAll: false},
                {name: "organisation", title: "Companies", searchedTerm: "", data: [], svg: require("@/assets/icons/filter-component/companies.svg"), dataFetched: false, page: 1, limit: 6, selectAll: false},
                {name: "location", title: "Locations", searchedTerm: "", data: [], svg: require("@/assets/icons/filter-component/locations.svg"), dataFetched: false, page: 1, limit: 6, selectAll: false},
                {name: "risks", title: "Risks", searchedTerm: "", data: [], svg: require("@/assets/icons/filter-component/risks.svg"), dataFetched: false, page: 1, limit: 6, selectAll: false},
                {name: "topicClassification", title: "Topic Classification", searchedTerm: "", data: [], svg: require("@/assets/icons/filter-component/topic-classification.svg"), dataFetched: false, page: 1, limit: 6, selectAll: false},
            ],
            loaderController: {
                news: {
                    loading: false,
                    success: false,
                    filterLoading: false,
                    filterSuccess: false,
                },
            },
            alertMessage: {
                success: null,
                error: null,
            },
            case_id: "",
            allNews: [],
            filteredNews: [],
            headers: [
                {
                    text: "",
                    value: "details",
                },
            ],
            itemKey: "title",
            searchCaseFilter: "",
            selectable: false,
            hasAction: false,
            pagination: {
                news: {
                    page: 1,
                    perPage: 20,
                    total_page: 1,
                },
            },
            monitoringStatus: false,
            searchKeyword: "",
            showResult: false,
            categories: "",
            codes: "",
            triggerRoute: true,
            isResultExpanded: false,
            open: false,
            allFilters: [],
            /**
             * @property
             * @name allSources - sources data along with sources filters and title
             *                  - each source data returned through a function generateFiltersWrapperModel
             *                      - containing source title, source name mapping, source value mapping, and filters allowed
             */
            allSources: {
                news: generateFiltersWrapperModel("News", "news", "news", ["start_date", "end_date", "language"], "tw-bg-green-100"),
                djfactiva: generateFiltersWrapperModel("DowJones Factiva", "factiva", "dowjonesfactiva", ["start_date", "end_date", "language_factiva", "dowjones_sources", "dowjones_jurisdiction"], "tw-bg-red-100"),
            },
            showFiltersPanel: false,
            selectedResultCard: null,
            showMoreInfo: false,
            showSearch: true,
            appliedFilterClicked: false,
            selectedCard: null,
            allHeadlines: [],
            currentPage: 1,
            apiCallPages: 1,
            totalResultSize: 0,
            request_id: null,
            totalRecordsSize: {
                visitedPages: [],
            },
            expandedLoading: false,
            searchClicked: false,
            isLoading: false,
            selectedKeywordsData: {
                keywords: {},
                nearCount: null,
            },
            tabName: "",
            sources: [],
            isExistingSearchQuery: false,
            searchKeywordStringFormatted: "",
            filterStartDate: "",
            filterEndDate: "",
            filterLanguages: [],
            allLanguages: [{group: "Languages", categories: ["en"]}],
            allRegions: [{group: "Jurisdiction", categories: [""]}],
            /**
             * @property
             * @name searchMode - start_date filter data
             */
            start_date: {
                label: "Start date",
                type: "date",
                value: "",
                props: {
                    placeholder: "Start Date",
                    format: "YYYY-MM-DD",
                },
                isSourceSpecific: false,
            },
            /**
             * @property
             * @name end_date - end_date filter data
             */
            end_date: {
                label: "End date",
                type: "date",
                value: "",
                props: {
                    placeholder: "End Date",
                    format: "YYYY-MM-DD",
                },
                isSourceSpecific: false,
            },
            /**
             * @property
             * @name language - language filter data
             */
            language: {
                label: "Languages",
                type: "multiselect",
                value: {name: "English", value: "en"},
                props: {
                    options: [],
                    groupLabel: "group",
                    groupValues: "categories",
                    trackBy: "value",
                    label: "name",
                    multiple: false,
                    groupSelect: false,
                    checkboxes: true,
                    placeholder: "Select Language",
                    tagPlaceholder: "",
                    closeOnSelect: true,
                    showLabels: false,
                    max: null,
                },
                isSourceSpecific: false,
            },
            /**
             * @property
             * @name language - factiva language filter data
             */
            language_factiva: {
                label: "Languages",
                type: "multiselect",
                value: [{name: "English", value: "en"}],
                props: {
                    options: [],
                    groupLabel: "group",
                    groupValues: "categories",
                    groupSelect: true,
                    trackBy: "value",
                    label: "name",
                    multiple: true,
                    limit: 3,
                    checkboxes: true,
                    placeholder: "Select Language",
                    tagPlaceholder: "",
                    closeOnSelect: false,
                    showLabels: false,
                },
                isSourceSpecific: true,
            },
            /**
             * @property
             * @name dowjones_sources - dowjones sources filter data
             */
            dowjones_sources: {
                label: "Sources",
                type: "multiselect",
                value: [],
                props: {
                    options: [],
                    groupLabel: "group",
                    groupValues: "categories",
                    groupSelect: true,
                    // trackBy: "name",
                    // label: "name",
                    multiple: true,
                    limit: 3,
                    checkboxes: true,
                    placeholder: "Select source",
                    tagPlaceholder: "",
                    closeOnSelect: false,
                    showLabels: false,
                },
                isSourceSpecific: true,
            },
            dowjones_jurisdiction: {
                label: "Jurisdiction",
                type: "multiselect",
                value: [],
                props: {
                    options: [],
                    groupLabel: "group",
                    groupValues: "categories",
                    groupSelect: true,
                    trackBy: "value",
                    label: "name",
                    multiple: true,
                    limit: 3,
                    checkboxes: true,
                    placeholder: "Select Jurisdiction",
                    tagPlaceholder: "",
                    closeOnSelect: false,
                    showLabels: false,
                },
                isSourceSpecific: true,
            },
            searchQuery: "",
            entityCount: 1,
            isSavedLoading: {
                loading: false,
                loadingCardIndex: null,
            },
            perPage: 100,
            perPageOptions: [10, 20, 50, 100],
            order_by: {
                name: "Updated Date",
                value: "updated_at",
            },
            // sortOptions: [
            //     {
            //         name: "Published Date",
            //         value: "created_at",
            //     },
            //     {
            //         name: "Updated Date",
            //         value: "updated_at",
            //     },
            // ],
            sortKeysMapping: {
                created_at: {
                    news: "publishedAt",
                    dowjonesFactiva: "PublicationDate",
                },
                updated_at: {
                    news: "publishedAt",
                    dowjonesFactiva: "ModificationDate",
                },
            },
            appliedFilterCount: 0,
            filters: {
                saved: "all",
                monitoring: "all",
            },
            limitPagination: 100,
            sourceType: [],
            prevAppliedFilters: {
                monitoring: "all",
                saved: "all",
                sourceType: [],
                searchQuery: "",
            },
            timeline: [],
            isOldVersionSelected: false,
            getTotalResultsCount: 0,
            latestVersionTotalResults: 0,
            isComparisonModeOn: false,
            isFilterApplied: false,
            resetFilterApplied: false,
        };
    },
    computed: {
        ...mapGetters(["getAnalyzerOptions", "getCaseId", "getReadOnlyMode", "getAllToolsData", "getPermissions", "checkPermissionsFetched", "getProduct", "getCasePersonalData", "getAllMonitoringData", "getMonitoringDateFilter", "getSelectedToolQuery", "getKeywordProductMapEntities", "nerFilters", "getAutoAnalyze"]),

        searchQueryExists() {
            let flag = true;
            if (this.toolSearchQueries.findIndex((el) => el._id === this.getSelectedToolQuery?.query_id) < 0) flag = false;
            return flag;
        },

        /**
         * @property
         * @name news
         * @return {Array} - sort options based on order_by value
         */

        sortOptions() {
            if (this.order_by?.value == "updated_at") {
                return [
                    {
                        name: "Updated Date",
                        value: "updated_at",
                    },
                    {
                        name: "Created Date",
                        value: "created_at",
                    },
                ];
            }
            if (this.order_by.value == "created_at") {
                {
                    return [
                        {
                            name: "Created Date",
                            value: "created_at",
                        },
                        {
                            name: "Updated Date",
                            value: "updated_at",
                        },
                    ];
                }
            }
        },

        /**
         * @property
         * @name news
         * @return {Array} - return list of paginated search results - from news source
         */
        news() {
            let data = this.filteredNews;
            if (this.isMonitoringTab) {
                return data.slice((this.currentPage - 1) * this.perPage, this.currentPage * this.perPage);
            } else {
                if (!this.isLoading) {
                    return data.slice((this.currentPage - 1) * this.perPage, this.currentPage * this.perPage);
                }
            }
        },
        /**
         * @property
         * @name headlines
         * @return {Array} - return list of paginated search results - from djfactiva
         */
        headlines() {
            let data = [];
            const start = (this.pagination.news.page - 1) * this.perPage;
            const end = this.pagination.news.page * this.perPage - 1;
            this.allHeadlines.forEach((news, index) => {
                if (start <= index && index <= end) {
                    data.push(news);
                }
            });
            return data;
        },

        /**
         * @property
         * @name getTotalPages
         * @return {Number} - return number of total pages of search results
         */
        getTotalPages() {
            if (this.currentPage * 100 < this.getTotalResultsCount && !this.appliedFilterClicked) {
                return this.currentPage + 1;
            } else if (this.totalFilteredPages && this.appliedFilterClicked) return this.totalFilteredPages;
            if (this.isAllRunQueries && this.currentPage * 100 < this.totalRecordsInAllQueries) {
                return this.currentPage + 1;
            } else return this.currentPage;
        },

        // getTotalResultsCount() {
        //     let totalCount = 0;
        //     if(this.allSources["news"].isChecked) totalCount+= this.totalResultsCountNews;
        //     if(this.allSources["djfactiva"].isChecked) totalCount+= this.totalResultsCountDowjones;
        //     return totalCount;
        // },

        /**
         * @property
         * @name formattedSearchQueryWithKeywords
         * @return {String} - return formatted search query with keywords data
         */
        formattedSearchQueryWithKeywords() {
            if (this.isExistingSearchQuery) return this.searchKeywordStringFormatted;
            else return this.formatSearchQueryString(this.searchKeyword, this.selectedKeywordsData);
        },

        /**
         * @property
         * @name checkMarkAllDone
         * @return {Boolean} - Monitoring - check if all results have been marked done or not
         */
        checkMarkAllDone() {
            if (this.filteredNews && this.filteredNews.length) return this.filteredNews.slice((this.currentPage - 1) * this.perPage, this.currentPage * this.perPage).every((result) => result.api_all_data.changes_viewed);
            else return false;
        },

        /**
         * @property
         * @name getTotalMonitoringPages
         * @return {Number} - return number of total pages of monitoring search results
         */
        getTotalMonitoringPages() {
            return Math.ceil(this.filteredNews.length / this.perPage);
        },

        /**
         * @property
         * @name expandedContent
         * @return {Object} - return data for the selected card
         */
        expandedContent() {
            let index = this.selectedCard;
            if (index !== null) {
                if (this.isOldVersionSelected) return this.timeline[index];
                else return this.filteredNews[index];
            } else return {};
        },

        /**
         * @property
         * @name isSearchBtnDisabled
         * @return {Boolean} - check if search button is disabled (if any source is not selected)
         */
        isSearchBtnDisabled() {
            let isCheckedFilterExists = false;
            for (const filter in this.allSources) {
                if (this.allSources[filter].isChecked) {
                    isCheckedFilterExists = true;
                    break;
                }
            }
            return !isCheckedFilterExists;
        },

        /**
         * @property
         * @name isMonitoringTab
         * @return {Boolean} - check if monitoring tab is selected
         */

        isMonitoringTab() {
            return this.tabName === "monitoring";
        },
        /**
         * @property
         * @name getSourcesList
         * @return {Array} - return list of sources (output filters)
         */

        last_run_date() {
            return this.getSelectedToolQuery?.query?.last_runtime;
        },
        isEnabledApplyButton() {
            let {monitoring, saved} = this.filters;
            let appliedFilters = {
                monitoring,
                saved,
                sourceType: this.sourceType,
                searchQuery: this.searchQuery,
            };
            return isEqual(appliedFilters, this.prevAppliedFilters);
        },
    },
    watch: {
        showResult() {
            this.$el.scroll(0, 0);
        },
        "$route.query.q"(to, from) {
            if (this.triggerRoute) {
                this.redirectToQuery();
            }
        },

        // searchKeyword(val) {
        //     this.showResult = val ? false : true;
        // },
    },
    async mounted() {
        for (let source in this.allSources) {
            this.allSources[source].filters = this.allSources[source].filters.map((filter) => this[filter]);
        }
        this.case_id = this.$route.params.id;
        this.tabName = this.$route.name;
        const dataRes = this.getToolsData(this.tabName, "news-tool");
        this.handleResetFilters();
        await this.getLanguages();
        await this.fetchDownJonesSources();
        await this.fetchDownJonesRegions();
        this.count += 1;
        if (dataRes) this.redirectToQuery(dataRes);
    },
    created() {
        EventBus.$on("updateCategories", async (data) => {
            this.updateCardCategories(data);
        });
        EventBus.$on("openShowMorePanel", async (data) => {
            await this.openShowMorePanel(data);
        });
        EventBus.$on("popOutWebpage", (data) => {
            this.popOutWebpage(data);
        });
        EventBus.$on("closeMoreInfo", () => {
            this.handleCloseMoreInfo();
        });
        EventBus.$on("handleDone", (data) => {
            let result = cloneDeep(this.allNews);
            if (!data.oldVersion) {
                let index = result.findIndex((el) => el.curr_index === data.card.curr_index);
                if (index > -1) {
                    result[index] = data.card;
                    this.allNews = result;
                    //this.checkMarkAllDone();
                }
            } else {
                let oldVersionResults = cloneDeep(this.timeline);
                let index = oldVersionResults.findIndex((el) => el.curr_index === data.card.curr_index);
                if (index > -1) {
                    oldVersionResults[index] = data.card;
                    this.timeline = oldVersionResults;
                    // this.checkMarkAllDone();
                }
            }
            this.filterData();
            this.$emit("refreshQueries");
            this.$forceUpdate();
        });
        EventBus.$on("handleNewsResultSave", (data) => {
            this.handleSavedCard(data);
        });
        EventBus.$on("nlpDone", (data) => {
            this.filteredNews.map((el) => {
                if (el.api_all_data.analysis_status != "pending" || !el.api_all_data.analysis_status) {
                    let array = [];
                    if (el.api_all_data.esg_category_recognition) {
                        array.push("esg_category_recognition");
                    }
                    if (el.api_all_data.entity_recognition) {
                        array.push("entity_recognition");
                    }
                    if (el.api_all_data.multilabel) {
                        array.push("multilabel");
                    }
                    if (el.api_all_data.risk_category_recognition) {
                        array.push("risk_category_recognition");
                    }
                    el.api_all_data.analysis_remaining = this.getAnalyzerOptions.length - array.length;
                }
            });
        });
    },
    beforeDestroy() {
        clearInterval(this.interval);
        this.setToolsData(this.tabName, "news-tool");
        EventBus.$off("openShowMorePanel");
        EventBus.$off("popOutWebpage");
        EventBus.$off("closeMoreInfo");
        EventBus.$off("handleDone");
        EventBus.$off("handleNewsResultSave");
        EventBus.$off("startAutoAnalyzer");
        EventBus.$off("nlpDone");
    },
    methods: {
        getMappingValue,
        ...mapMutations(["SET_ALL_TOOLS_DATA", "SET_ALL_MONITORING_DATA"]),
        ...mapActions(["setSelectedKeywordsData", "setSearchWordsData", "getToolsStatus", "setToolsFilterData"]),
        updateCardCategories(data) {
            data.forEach((res) => {
                this.filteredNews.forEach((doc) => {
                    if (doc.api_all_data.doc_id == res.doc_id) {
                        doc.api_all_data.analysis_status = res.analysis_status ? res.analysis_status : "pending";
                        doc.api_all_data.risk_categories = res.risk_categories;
                        doc.api_all_data.analysis_remaining = 0;
                    }
                });
            });
        },
        handleFilterPanel() {
            this.showFiltersPanel = !this.showFiltersPanel;
            if (this.showFiltersPanel) {
                this.filtersOptions.forEach((res) => {
                    if (!["saved", "monitoring"].includes(res.name)) {
                        res.data = [];
                        res.dataFetched = false;
                        res.page = 1;
                    }
                });
            }
        },
        filterApplied() {
            const staticFilters = ["saved", "monitoring"];
            let isFilterApplied = false;
            let staticOptions = this.filtersOptions.filter((res) => staticFilters.includes(res.name)).map((doc) => doc.data);
            staticOptions = [].concat(...staticOptions);
            const filtersList = this.filtersOptions.filter((res) => {
                if (!staticFilters.includes(res.name)) {
                    const checked = res.data.filter((r) => r.checked);
                    if (checked.length > 0) {
                        return true;
                    } else {
                        return false;
                    }
                }
            });
            if (filtersList.length > 0) {
                isFilterApplied = true;
            }
            staticOptions.forEach((res) => {
                if (res.key != "all" && res.checked) {
                    isFilterApplied = true;
                }
            });

            return isFilterApplied;
        },
        getSourcesList() {
            let sourcesNames = [];
            let sources = [];
            this.allNews?.forEach((src) => {
                if (src?.key && !sources.includes(src?.key)) {
                    sources.push(src.key);
                    sourcesNames.push({
                        name: src.key,
                    });
                }
            });
            return sourcesNames;
        },
        addChecked(res) {
            res.checked = false;
            res.children.forEach((el) => {
                if (el.children != null) {
                    this.addChecked(el);
                } else {
                    el.checked = false;
                }
            });
        },
        async fetchFilterData(filter, customTitles) {
            if (!filter.dataFetched && !customTitles.includes(filter.name)) {
                const url = "/ner-analyzer/global-filter-extended";
                let {data} = await SaasAxios.post(url, {case_id: this.$route.params.id, keyword_type: filter.name, page: filter.page, limit: filter.limit, tool: "news", request_id: this.request_id});
                data.data.forEach((res) => {
                    res.checked = false;
                });
                filter.data = data.data;
                filter.dataFetched = true;
            }
            if (filter.name == "topicClassification" && !filter.dataFetched) {
                const ner_categories = await SaasAxios.get(`/case-data/${this.$route.params.id}/ner-categories?tool=news&query_id=${this.request_id}`);
                if (ner_categories?.data) {
                    ner_categories?.data.forEach((res) => {
                        // res.checked = false;
                        if (res.children) {
                            this.addChecked(res);
                        } else res.checked = false;
                    });
                    this.savedTopicData = ner_categories.data;
                    filter.data = ner_categories?.data;
                    filter.dataFetched = true;
                }
            }
            if (filter.name == "internetQueries" && !filter.dataFetched) {
                let reqBody = {case_id: this.case_id, run_status: this.run_status, done_status: this.doneFilterVal, visited_status: this.visitedFilterVal, adverse: this.params.adverse};
                const {data} = await SaasAxios.post("/search-engine/queryset/queries", reqBody);
                let queries = data.queries.map((res) => res.search_query);
                let finalData = [];
                queries.forEach((res) => {
                    finalData.push({key: res.replaceAll('"', ""), checked: false, count: 1});
                });
                this.savedInternetQueries = finalData;
                filter.data = finalData;
                filter.dataFetched = true;
            }

            if (filter.name == "sources") {
                filter.data = this.getSourcesList();
            }
        },
        handleInternetQuerySearch(filter) {
            let arr = [];
            if (filter.data.length === 0) filter.data = this.savedInternetQueries;
            let regex = new RegExp(filter.searchedTerm);
            for (const item of filter.data) {
                if (regex.test(item.key)) {
                    arr.push(item);
                }
            }
            if (filter.searchedTerm) {
                filter.data = arr;
            } else {
                filter.data = this.savedInternetQueries;
            }
        },
        filterInternetQueries(items, query) {
            let arr = [];
            let regex = new RegExp(query);
            for (const item of items.data) {
                if (regex.test(item.key)) {
                    arr.push(item);
                }
            }
        },
        filterItemsRecursively(items, query, parent) {
            let arr = [];
            for (const item of items) {
                if (this.caseInsensitiveSearch(item.category, query)) {
                    if (item.level == 1) {
                        arr.push(item);
                    } else arr.push(parent);
                } else {
                    if (item?.children?.length) {
                        arr.push(...this.filterItemsRecursively(item.children, query, parent || item));
                    }
                }
            }
            arr = this.removeDuplicates(arr);
            return arr;
        },
        removeDuplicates(arr) {
            return [...new Set(arr)];
        },
        caseInsensitiveSearch(targetString, searchString) {
            const regex = new RegExp(searchString, "i");
            return regex.test(targetString);
        },
        handleTopicSearch(filter) {
            // filter.data = filter.data.filter(item => this.caseInsensitiveSearch(item.category, filter.searchedTerm))
            if (filter.data.length === 0) filter.data = this.savedTopicData;
            let searchResults = this.filterItemsRecursively(filter.data, filter.searchedTerm);
            filter.data = filter.searchedTerm ? searchResults : this.savedTopicData;
        },

        async handleMoreData(filter) {
            filter.dataFetched = false;
            const url = "/ner-analyzer/global-filter-extended";
            let {data} = await SaasAxios.post(url, {case_id: this.$route.params.id, keyword_type: filter.name, page: filter.page, limit: filter.limit, search_string: filter.searchedTerm, tool: "news", request_id: this.request_id});
            data.data.forEach((res) => {
                res.checked = false;
            });
            filter.data.push(...data.data);
            filter.dataFetched = true;
            filter.selectAll = false;
        },
        handleSearchboxChange: debounce(async function(filter, caseId) {
            if (filter.name != "internetQueries") {
                if (!filter.searchedTerm) {
                    filter.page = 1;
                    filter.limit = 6;
                }
                filter.dataFetched = false;
                const url = "/ner-analyzer/global-filter-extended";
                let {data} = await SaasAxios.post(url, {case_id: caseId, keyword_type: filter.name, page: filter.page, limit: filter.limit, search_string: filter.searchedTerm, tool: "news", request_id: this.request_id});
                data.data.forEach((res) => {
                    res.checked = false;
                });
                filter.data = data.data;
                filter.dataFetched = true;
            }
        }, 1000),
        startLoader(section) {
            this.loaderController[section].success = false;
            this.loaderController[section].loading = true;
        },
        handleOutside() {
            this.open = false;
        },
        onToggle() {
            this.open = !this.open;
        },
        async handleSavedCard(cardData) {
            if (this.isSavedLoading.loading) return;
            if (cardData.api_all_data && cardData.api_all_data.doc_id) {
                this.isSavedLoading = {
                    loading: true,
                    loadingCardIndex: cardData.api_all_data.doc_id,
                };
                let sources = [];
                if (cardData.key === "dowJonesFactiva") {
                    sources.push("dowjonesfactiva");
                } else {
                    sources.push("news");
                }
                let url = `/osint-tools/card/mark-as-saved`;
                let payload = {
                    sources: sources,
                    doc_id: cardData.api_all_data.doc_id,
                    saved: !cardData.api_all_data.saved,
                    card_data: {
                        created_at: cardData?.created_at ?? null,
                        updated_at: cardData?.updated_at ?? null,
                        case_id: this.case_id,
                        query_id: this.request_id,
                        tool: "tool-news-meta",
                        tool_name: this.$route.params.toolName,
                    },
                };
                try {
                    const {data} = await mainAxios.post(url, payload);
                    if (data.success === true) {
                        let result = cloneDeep(this.allNews);
                        let elementIndex = result.findIndex((obj) => obj.api_all_data.doc_id === cardData.api_all_data.doc_id);
                        let resultSavedElement = result[elementIndex];
                        resultSavedElement.api_all_data.saved = !resultSavedElement.api_all_data.saved;
                        result.splice(elementIndex, 1, resultSavedElement);
                        this.allNews = result;
                        // this.allNews = result;

                        // let resultnews = cloneDeep(this.allNews);
                        // let newselementIndex = resultnews.findIndex((obj) => obj.api_all_data.doc_id === cardData.api_all_data.doc_id);
                        // let resultNewsSavedElement = resultnews[newselementIndex];
                        // resultNewsSavedElement.api_all_data.saved = !resultNewsSavedElement.api_all_data.saved;
                        // resultnews.splice(elementIndex, 1, resultNewsSavedElement);
                        // this.allNews = resultnews;

                        this.isSavedLoading = {
                            loading: false,
                            loadingCardIndex: null,
                        };
                        this.$toast.success("Updated!");
                        this.filterData();
                        this.$emit("refreshQueries");
                        this.$forceUpdate();
                    }
                } catch (error) {
                    this.isSavedLoading = {
                        loading: false,
                        loadingCardIndex: null,
                    };
                    this.$toast.warning("Please try again after sometime!");
                }
            }
        },
        successLoader(section) {
            this.loaderController[section].success = true;
            this.loaderController[section].loading = false;
        },
        resetLoader(section) {
            this.loaderController[section].success = false;
            this.loaderController[section].loading = false;
        },
        getFormattedTime(ts) {
            let date = DateTime.fromISO(ts);
            return date.toFormat("EEE, dd MMM yyyy, hh:mm:ss a");
        },
        getTime(item) {
            let date = new Date(item.created_at);
            return date ? moment(item).fromNow() : "";
        },
        disabledRange(date) {
            return date > new Date();
        },
        disableEndDate(date) {
            let disabledate = new Date(new Date(this.start_date.value).getTime() - 24 * 60 * 60 * 1000);
            return date <= disabledate || date >= new Date();
        },

        paginateNews(pageNo) {
            this.pagination.news.page = pageNo;

            if (pageNo === this.pagination.news.total_page) {
                this.getNews(true);
            }
        },

        /**
         * When tab is monitoring
         * Fetch monitoring results
         * @param {array} sources: []
         */
        async monitoringSearch(sources) {
            this.startLoader("news");
            if (this.apiCallPages == 1) this.allNews = [];
            this.showSearch = false;
            this.showResult = true;
            this.isLoading = true;
            if (this.allNews.length == 0) this.currentPage = 1;
            let offset = this.allNews.length;
            this.handleCloseMoreInfo();
            let values = await monitoringSearchData(this.case_id, sources, this.$route.params.toolName, this.getMonitoringDateFilter, this.getSelectedToolQuery, offset);
            this.latestVersionTotalResults = values.totalResults;
            if (values.values?.length > 0) {
                let news = this.getMappingValue(
                    values.values.filter((val) => val?.doc_source === "news"),
                    "news",
                    NEWS_TOOL_MAPPING,
                    "News"
                );
                let factiva_news = this.getMappingValue(
                    values.values.filter((val) => val?.doc_source === "dowjonesfactiva"),
                    "dowJonesFactiva",
                    NEWS_TOOL_MAPPING,
                    "DowJones Factiva"
                );
                this.allNews = [...this.allNews, ...news, ...factiva_news];
                this.allNews = this.allNews.map((ele, index) => ({...ele, curr_index: index}));
                let cont = document.getElementById("result-list");
                if (cont) cont.scrollTop = 0;
            }
            this.isLoading = false;
            this.resetLoader("news");
            this.$emit("getInitialQueryCount");
        },

        async clearFilters() {
            this.currentPage = 1;
            // this.start_date.value = "";
            // this.end_date.value = "";
            // this.language.value = null;
            // this.language_factiva.value.value = [];
            // this.dowjones_jurisdiction.value.value = [];
            this.sourceType = [];
            this.isFilterApplied = false;
            this.filterStartDate = "";
            this.filterEndDate = "";
            this.filterLanguages = [];
            this.apiCallPages = 1;
            this.searchQuery = "";
            this.filters = {
                saved: "all",
                monitoring: "all",
                sources: [],
            };
            await this.onApplyFilter();
            this.sortResults();
        },

        async resetQueryFilter() {
            const filters = {
                monitoring: "all",
                saved: "all",
            };
            this.setToolsFilterData(filters);
            await this.getToolsStatus();
        },

        async manualSearch() {
            // this.isExistingSearchQuery = false;
            await this.search();
        },

        async handleSearch() {
            if (!this.isSearchBtnDisabled) {
                let globalFilters = false;
                globalFilters = await this.checkGlobalFilters();
                if (!globalFilters) {
                    await this.manualSearch();
                    await this.resetQueryFilter();
                }
            }
        },

        async checkGlobalFilters() {
            if (this.nerFilters) {
                try {
                    const confirm = await this.$refs["modal-confirm"].show({
                        title: "Reset Global Filters",
                        message: "There are some Global Filters applied. Are you sure you want to reset all Global Filters ?",
                        autoclose: false,
                    });
                    if (confirm) {
                        this.$emit("handleNerClear");
                        this.$refs["modal-confirm"].close();
                        return false;
                    } else return true;
                } catch (error) {
                    this.$refs["modal-confirm"].close();
                }
            } else return false;
        },

        async search(refreshFlag) {
            this.totalRecordsSize = {
                visitedPages: [],
            };
            this.triggerRoute = false;
            // this.isAllRunQueries = false;

            if (this.searchKeyword || this.isAllRunQueries) {
                const searchWords = this.splitSearchWords(this.searchKeyword);
                this.setSearchWordsData(searchWords);

                if (!this.allSources["news"].isChecked && !this.allSources["djfactiva"].isChecked && !this.isAllRunQueries) {
                    this.$toast.error("Please Select a Source");
                    return;
                }
                let headers = {
                    "x-tool-name": this.$route.params.toolName,
                    "x-visited": true,
                };
                const sourcesCallBody = this.getSourcesBody();
                if (sourcesCallBody.from_date > sourcesCallBody.to_date) {
                    this.$toast.error("start date cannot be greater than the end date");
                    return;
                }
                if (sourcesCallBody.from_date && !sourcesCallBody.to_date) {
                    this.$toast.error("please select end date");
                    return;
                }
                if (!sourcesCallBody.from_date && sourcesCallBody.to_date) {
                    this.$toast.error("please select start date");
                    return;
                }
                this.sources = sourcesCallBody.sources;
                if (this.isMonitoringTab) {
                    await this.monitoringSearch(sourcesCallBody.sources);
                    if (this.apiCallPages === 1 && this.latestVersionTotalResults > 100) {
                        this.apiCallPages = 2;
                        await this.monitoringSearch(sourcesCallBody.sources);
                    }
                    this.filterData();
                    return;
                }
                this.startLoader("news");
                if (this.allSources["djfactiva"].isChecked) {
                    sourcesCallBody.sort_by = this.sortKeysMapping[this.order_by?.value]["dowjonesFactiva"] || "";
                    sourcesCallBody.sort_order = "desc";
                }
                if (this.request_id) sourcesCallBody.language_factiva = this.getSelectedToolQuery?.query?.query?.language_factiva;
                else if (this.allSources["djfactiva"].isChecked) {
                    if (sourcesCallBody?.language_factiva?.length > 0) {
                        sourcesCallBody.language_factiva = uniqBy(sourcesCallBody.language_factiva);
                    } else sourcesCallBody.language_factiva = ["en"];
                } else sourcesCallBody.language_factiva = [];
                const {data: sourcesCall} = await axios.post("/news/search/sources", sourcesCallBody, {headers});
                this.request_id = sourcesCall.request_id;
                this.redirectTo();
                if (this.apiCallPages == 1) this.allNews = [];
                this.totalResultSize = 0;
                this.currentPage = 1;
                await this.getNews(refreshFlag);
                // }
                // }
                // }
                this.triggerRoute = true;
            } else {
                this.$toast.error("Please enter a valid input");
            }
        },

        /**
         * @function
         * @name handleSourceChange
         * @params {e} - pointer event for source input checkbox
         */
        handleSourceChange(e) {
            if (this.sources.includes(e.target.value)) {
                const filterSource = this.sources.filter((el) => el !== e.target.value);
                this.sources = filterSource;
            } else {
                this.sources.push(e.target.value);
            }

            for (let source in this.allSources) {
                if (this.allSources[source].value === e.target.value) {
                    this.allSources[source].isChecked = !this.allSources[source].isChecked;
                    break;
                }
            }
            // if (this.allSources["news"].isChecked) {
            //     this.language.props.max = 1;
            //     this.language.props.groupSelect = false;
            //     this.language.value = null;
            // } else {
            //     this.language.props.max = null;
            //     this.language.props.groupSelect = true;
            // }
        },
        /**
         * @function
         * @name handleSourceExpand
         * @params {source, value} - source & value (News/Dowjones)
         */
        handleSourceExpand(source, value, toggle) {
            if (toggle) this.allSources[source].isChecked = !this.allSources[source].isChecked;
            if (this.sources.includes(value)) {
                const filterSource = this.sources.filter((el) => el !== value);
                this.sources = filterSource;
            } else {
                this.sources.push(value);
            }
            // if (this.allSources["news"].isChecked) {
            //     this.language.props.max = 1;
            //     this.language.props.groupSelect = false;
            //     this.language.value = [];
            // } else {
            //     this.language.props.max = null;
            //     this.language.props.groupSelect = true;
            // }
        },
        /**
         * @function
         * @name updateSource
         * @params {e} - pointer event for source input checkbox (News/Dowjones)
         */
        updateSource(e, source) {
            if (e.target.checked && this.allSources[source].filters && this.allSources[source].filters.length) {
                this.allSources[source].isModalVisible = true;
                //this.filterValue = this.allSources[source]
            } else {
                this.handleSourceChange(e);
            }
            // this.manualSearch();
        },
        handlePage(page_number) {
            this.scrollToTop();
            this.currentPage = page_number;
        },

        scrollToTop() {
            const container = document.getElementById("result-list");
            if (container) container.scrollTop = 0;
        },

        /**
         * @function
         * @name filterData - filter Data as per the filters applied
         */
        filterData() {
            let data = cloneDeep(this.allNews);
            let searchKeyword = this.searchQuery.toLowerCase() ?? "";

            if (this.sourceType?.length > 0) {
                data = data.filter((adv) => {
                    return this.sourceType.some((source) => adv?.key === source.name);
                });
            }

            this.filteredNews = data.filter((ele) =>
                ele?.title
                    ?.replace(/<(.|\n)*?>/g, "")
                    .toLowerCase()
                    .includes(searchKeyword)
            );
            this.filteredNews = this.filteredNews.map((ele, index) => ({...ele, curr_index: index}));
            // let allFilters = ["filters.saved", "filters.monitoring", "sourceType"];
            // let that = this;
            // this.allFilters = [];
            // for (let filter in allFilters) {
            //     let filterValues = get(that, allFilters[filter]);
            //     if (allFilters[filter].includes(".")) {
            //         filterValues = filterValues === "all" ? [] : [filterValues];
            //     }
            //     if(this.isEnabledApplyButton) {
            //         for (const value in filterValues) {
            //             this.allFilters.push({
            //                 name: allFilters[filter],
            //                 value: filter === "sourceType" ? filterValues[value].name : filterValues[value],
            //             });
            //         }
            //     }

            // }
            // this.appliedFilterCount = 0;
            // if(this.isFilterApplied) {
            //     if (this.filters.saved !== "all") this.appliedFilterCount += 1;
            //     if (this.filters.monitoring !== "all") this.appliedFilterCount += 1;
            //     if (this.searchQuery) this.appliedFilterCount += 1;
            //     if (this.sourceType?.length) this.appliedFilterCount += 1;
            // }
            this.$forceUpdate();
        },
        getSourcesBody() {
            let keywords = Object.entries(this.selectedKeywordsData.keywords);
            keywords = keywords.map((el) => ({
                keyword_id: el[0],
                words: el[1],
            }));
            let sourcesCallBody = {
                sources: [],
                query: this.formattedSearchQueryWithKeywords,
                keywords,
                nearness: parseInt(this.selectedKeywordsData?.nearCount, 10),
                from_date: this.start_date.value ? moment(this.start_date.value).format("YYYY-MM-DD") : null,
                to_date: this.end_date.value ? moment(this.end_date.value).format("YYYY-MM-DD") : null,
                // this.language?.value?.length > 1 ? this.language?.value?.map((el) => el.value):
                language: this.language?.value?.value,
                language_factiva: this.language_factiva?.value.length > 0 ? this.language_factiva?.value.map((lan) => lan?.value) : null,
                page: 1,
                limit: this.perPage,
                news_sources: this.dowjones_sources.value ? this.dowjones_sources.value : null,
                regions: this.dowjones_jurisdiction?.value.length > 0 ? this.dowjones_jurisdiction?.value.map((reg) => reg.value) : null,
            };

            if (!this.isSourceAuth("news")) {
                this.allSources["news"].isChecked = false;
                this.sources = this.sources.filter((e) => e !== "news");
            }
            if (!this.isSourceAuth("factiva")) {
                this.allSources["djfactiva"].isChecked = false;
                this.sources = this.sources.filter((e) => e !== "dowjonesfactiva");
            }

            if (!this.allSources["djfactiva"].isChecked) {
                sourcesCallBody.language_factiva = null;
            }
            if (!this.allSources["news"].isChecked) {
                sourcesCallBody.language = null;
            }

            if (this.allSources["news"].isChecked) {
                sourcesCallBody.sources.push("news");
            }
            if (this.allSources["djfactiva"].isChecked) {
                sourcesCallBody.sources.push("dowjonesfactiva");
            }
            sourcesCallBody.sources.sort((a, b) => a.localeCompare(b));
            return sourcesCallBody;
        },

        /**
         * @function
         * @name redirectTo - function to update input filters data to the route
         */
        redirectTo() {
            this.handleResetFilters();
            let query = {};
            if (this.request_id)
                query = {
                    query_id: this.request_id,
                };

            this.$router.push({path: this.$route.path, query}).catch(() => {});
            this.prevAppliedFilters = {
                monitoring: "all",
                saved: "all",
                sourceType: [],
                searchQuery: "",
            };
        },

        /**
         * @function
         * @name getLanguages - function to get list of languages
         */
        async getLanguages() {
            let url = "/news/languages";
            let language_factiva_url = "/news/languages/factiva";
            let allLanguages = [];
            await axios
                .get(url)
                .then((response) => {
                    if (response && response.data) {
                        const language = response.data.languages.map((el) => ({
                            name: el.label,
                            value: el.value,
                        }));
                        allLanguages = language;
                        this.language.props.options = [{group: "language", categories: language}];
                    }
                })
                .catch((error) => {});
            try {
                const {data} = await axios(language_factiva_url);
                if (data) {
                    const language = data.languages.map((el) => ({
                        name: el.label,
                        value: el.value,
                    }));
                    allLanguages = [...allLanguages, ...language];
                    this.language_factiva.props.options = [{group: "languages", categories: language}];
                    this.allLanguages = [{group: "languages", categories: allLanguages}];
                }
            } catch (error) {}
        },

        resetPage() {
            this.currentPage = 1;
            this.showMoreInfo = false;
            this.closeExpandedResult();
        },

        closeExpandedResult() {
            this.isResultExpanded = false;
            this.selectedResultCard = null;
        },

        limitText(length) {
            return ` +${length}`;
        },
        async handleApplyFilters() {
            this.handleCloseMoreInfo();
            this.appliedFilterClicked = true;
            if (this.totalFilteredPages <= 1) {
                this.currentPage = 1;
                this.totalRecordsSize = {
                    visitedPages: [],
                };
                this.totalRecordsSize.visitedPages.push(this.currentPage);
            }
            this.isLoading = true;
            let reqBody = {
                query: this.formattedSearchQueryWithKeywords,
                from_date: this.start_date.value ? moment(this.start_date.value).format("YYYY-MM-DD") : null,
                to_date: this.end_date.value ? moment(this.end_date.value).format("YYYY-MM-DD") : null,
                language: this.language?.value?.value,
                request_id: this.request_id,
                page: this.currentPage,
                limit: this.limitPagination,
                sort_by: this.sortKeysMapping[this.order_by?.value]["news"] || "",
                sort_order: "desc",
            };

            // if (this.filters.saved !== "all" && this.isFilterApplied) {
            //     reqBody.saved = this.filters.saved === "saved";
            // }
            // if (this.filters.monitoring !== "all" && this.isFilterApplied) {
            //     reqBody.monitoring_status = this.filters.monitoring === "monitoring";
            // }
            if (this.getFilterPayload("person").length > 0 || this.getFilterPayload("location").length > 0 || this.getFilterPayload("organisation").length > 0 || this.getFilterPayload("risks").length > 0 || this.getSelectedTopics(this.filtersOptions.find((res) => res.name == "topicClassification")?.data).length > 0) {
                reqBody.output_filters = {...reqBody.output_filters, news_person: this.getFilterPayload("person"), news_location: this.getFilterPayload("location"), news_company: this.getFilterPayload("organisation"), risk_categories: this.getFilterPayload("risks"), topic_categories: this.getSelectedTopics(this.filtersOptions.find((res) => res.name == "topicClassification")?.data)};
            }
            reqBody.analysis_config = this.getAnalyzerOption();
            let headers = {
                "x-tool-name": this.$route.params.toolName,
                "x-visited": true,
                "x-refresh": false,
            };

            if (!this.getFilterPayload("monitoring").includes("all")) {
                reqBody.monitoring_status = this.getFilterPayload("monitoring").includes("monitoring") ? true : false;
            }

            if (!this.getFilterPayload("saved").includes("all")) {
                reqBody.saved = this.getFilterPayload("saved").includes("saved") ? true : false;
            }

            const {data} = await axios.post("/news/search", reqBody, {
                headers,
            });

            let newsData = data.data;

            if (this.sourceType?.length > 0) {
                newsData = newsData.filter((adv) => {
                    return this.sourceType.some((source) => adv?.key === source.name);
                });
            }
            const mappingValue = this.getMappingValue(newsData, "news", NEWS_TOOL_MAPPING, "News");

            this.filteredNews = mappingValue;
            this.filteredNews = this.filteredNews.map((ele, index) => ({...ele, curr_index: index}));

            this.isLoading = false;
            let count = 0;
            this.filtersOptions.forEach((res) => {
                res.data.forEach((doc) => {
                    if (doc.checked) {
                        count += 1;
                    }
                });
            });
            this.getTotalResultsCount = data.TotalRecords ? data.TotalRecords : this.filteredNews.length;
            this.appliedFilterCount = count;
            this.totalFilteredPages = this.filteredNews.length / this.perPage;
            if (this.totalFilteredPages % 2 !== 0) this.totalFilteredPages = Math.floor(this.totalFilteredPages) + 1;
        },
        async handleResetFilters() {
            this.handleCloseMoreInfo();
            this.resetFilterApplied = true;
            this.appliedFilterClicked = false;
            this.totalFilteredPages = 1;
            const staticFilters = ["sources", "monitoring", "saved"];
            this.filtersOptions.forEach((res) => {
                if (!staticFilters.includes(res.name)) {
                    res.data = [];
                    res.dataFetched = false;
                    res.page = 1;
                    res.selectAll = false;
                    res.searchedTerm = null;
                } else if (res.name == "sources") {
                    res.data.forEach((res) => {
                        res.checked = false;
                    });
                    res.selectAll = false;
                } else if (res.name == "saved") {
                    res.data = [
                        {name: "All", checked: true, key: "all"},
                        {name: "Saved", checked: false, key: "saved"},
                        {name: "Not Saved", checked: false, key: "notSaved"},
                    ];
                } else if (res.name == "monitoring") {
                    res.data = [
                        {name: "All", checked: true, key: "all"},
                        {name: "Monitoring", checked: false, key: "monitoring"},
                        {name: "Not Monitoring", checked: false, key: "notMonitoring"},
                    ];
                }
            });
            if (this.news.length == 0) {
                this.currentPage = 1;
            }
            let count = 0;
            this.filtersOptions.forEach((res) => {
                res.data.forEach((doc) => {
                    if (doc.checked) {
                        count += 1;
                    }
                });
            });
            this.appliedFilterCount = count;
            this.count += 1;
        },
        getFilterPayload(name) {
            return this.filtersOptions
                .find((res) => res.name == name)
                ?.data.filter((doc) => doc.checked == true)
                .map((res) => res.key);
        },

        getSelectedTopics(nodes) {
            let arr = [];
            for (let child of nodes) {
                if (child.checked) arr.push(child.category);
                if (child.children && Array.isArray(child.children) && child.children.length > 0) arr = [...arr, ...this.getSelectedTopics(child.children)];
            }
            return arr;
        },

        getAnalyzerOption() {
            let config = {};
            this.getAnalyzerOptions.forEach((res) => {
                if (this.getPermissions.find((perm) => FEATURES.tools[perm] == res.key)) {
                    config[res.payload_name] = res.selected;
                }
            });
            return config;
        },

        /**
         * @function
         * @name getNews - function to fetch news data from different sources
         */
        async getNews(refreshFlag, filters = false, pollingFetch = false) {
            document.getElementById("result-list")?.scrollTo({
                top: 0,
                behavior: "smooth",
            });
            this.selectedCard = null;
            this.isOldVersionSelected = false;
            // this.getTotalResultsCount = 0;
            this.timeline = [];
            this.$emit("updateMonitoringCascadingTimeline", {
                timeline: this.timeline,
                meta_data: null,
            });
            this.showMoreInfo = false;
            this.totalFilteredPages = 1;
            if (this.totalRecordsSize.visitedPages.includes(this.currentPage) && !filters) {
                return;
            }
            this.showSearch = false;
            this.showResult = true;
            this.isLoading = true;

            // this.isAllRunQueries = false;
            this.allQueriesParams = null;
            this.offsetPag = 0;

            let headers = {
                "x-tool-name": this.$route.params.toolName,
                "x-visited": true,
                "x-refresh": refreshFlag || false,
            };
            let news, dowjonesFactiva;

            if (this.allSources["news"].isChecked) {
                let reqBody = {
                    query: this.formattedSearchQueryWithKeywords,
                    from_date: this.start_date.value ? moment(this.start_date.value).format("YYYY-MM-DD") : null,
                    to_date: this.end_date.value ? moment(this.end_date.value).format("YYYY-MM-DD") : null,
                    language: this.language?.value?.value,
                    request_id: this.request_id,
                    page: this.apiCallPages,
                    limit: this.limitPagination,
                    sort_by: this.sortKeysMapping[this.order_by?.value]["news"] || "",
                    sort_order: "desc",
                };

                if (this.nerFilters) reqBody.output_filters = this.nerFilters;
                // if (this.filters.saved !== "all" && this.isFilterApplied) {
                //     reqBody.saved = this.filters.saved === "saved";
                // }
                // if (this.filters.monitoring !== "all" && this.isFilterApplied) {
                //     reqBody.monitoring_status = this.filters.monitoring === "monitoring";
                // }
                if (filters) {
                    reqBody.output_filters = {...reqBody.output_filters, news_persons: this.getFilterPayload("person"), news_location: this.getFilterPayload("location"), news_company: this.getFilterPayload("organisation"), risk_categories: this.getFilterPayload("risks"), topic_categories: this.getSelectedTopics(this.filtersOptions.find((res) => res.name == "topicClassification")?.data)};
                }

                reqBody.analysis_config = this.getAnalyzerOption();
                news = axios.post("/news/search", reqBody, {
                    headers,
                });
            }
            if (this.request_id && this.getSelectedToolQuery?.query?.query?.language_factiva) {
                let language_factiva = this.getSelectedToolQuery?.query?.query?.language_factiva;
                this.language_factiva.value = [];
                if (language_factiva?.length) {
                    language_factiva?.forEach((lang) => {
                        let match = this.allLanguages[0]?.categories?.find((ele) => ele.value === lang);
                        this.language_factiva.value.push(match);
                    });
                }
                this.language_factiva.value = uniqBy(this.language_factiva.value, "name");
            } else {
                if (this.language_factiva?.value?.length > 0) {
                    this.language_factiva.value = uniqBy(this.language_factiva.value, "name");
                }
            }
            if (this.allSources["djfactiva"].isChecked) {
                const reqBody = {
                    query: this.formattedSearchQueryWithKeywords,
                    from_date: this.start_date.value ? moment(this.start_date.value).format("YYYY-MM-DD") : null,
                    to_date: this.end_date.value ? moment(this.end_date.value).format("YYYY-MM-DD") : null,
                    language: this.language_factiva?.value?.length
                        ? this.language_factiva?.value?.map((el) => {
                              if (el) return el.value;
                          })
                        : ["en"],
                    news_sources: this.dowjones_sources.value,
                    regions: this.dowjones_jurisdiction?.value?.length
                        ? this.dowjones_jurisdiction?.value?.map((el) => {
                              if (el) return el.value;
                          })
                        : this.dowjones_jurisdiction?.value,
                    request_id: this.request_id,
                    page: this.apiCallPages,
                    limit: this.limitPagination,
                    sort_by: this.sortKeysMapping[this.order_by?.value]["dowjonesFactiva"] || "",
                    sort_order: "desc",
                };
                reqBody.analysis_config = this.getAnalyzerOption();
                if (this.nerFilters) reqBody.output_filters = this.nerFilters;
                if (this.filters.saved !== "all" && this.isFilterApplied) {
                    reqBody.saved = this.filters.saved === "saved";
                }
                if (this.filters.monitoring !== "all" && this.isFilterApplied) {
                    reqBody.monitoring_status = this.filters.monitoring === "monitoring";
                }
                dowjonesFactiva = axios.post("/news/dowjones-headlines-search", reqBody, {headers});
            }
            Promise.all([news, dowjonesFactiva])
                .then(async (values) => {
                    if (this.currentPage === 1) {
                        this.totalResultSize = 0;
                    }
                    if (values[0]?.data?.data?.length > 0) {
                        const mappingValue = this.getMappingValue(values[0]?.data?.data, "news", NEWS_TOOL_MAPPING, "News");
                        // this.allNews = [...this.filteredNews, ...mappingValue];
                        this.allNews = [...this.allNews, ...mappingValue];
                        if (this.currentPage === 1) this.totalResultSize += values[0]?.data?.TotalRecords ?? 0;
                        // this.totalRecordsSize.news = values[0]?.data?.TotalRecords ?? 0;
                    }

                    if ((!values[0]?.data || !values[0]?.data?.data || values[0]?.data?.data?.length === 0) && values[0]?.data?.message) this.$toast.error(values[0]?.data?.message);

                    if ((!values[1]?.data || !values[1]?.data?.data || values[1]?.data?.data?.length === 0) && values[1]?.data?.message) this.$toast.error(values[1]?.data?.message);
                    else {
                        if (values[1]?.data && !values[1]?.data?.status) {
                            this.$toast.error(`DowJones Factiva: ${values[1]?.data?.error}`);
                        } else if (values[1]?.status === 500) {
                            this.$toast.error(`Something went wrong. Please try again.`);
                        } else {
                            if (values[1]?.data?.data?.length > 0) {
                                const mappingValue = this.getMappingValue(values[1]?.data?.data, "dowJonesFactiva", NEWS_TOOL_MAPPING, "DowJones Factiva");
                                // this.allNews = [...this.filteredNews, ...mappingValue];
                                this.allNews = [...this.allNews, ...mappingValue];
                                if (this.currentPage === 1) this.totalResultSize += values[1]?.data?.total ?? 0;
                                // this.totalRecordsSize.dowjones = values[1]?.data?.data?.TotalRecords ?? 0;
                            }
                        }
                    }

                    if (values.every((element) => element === undefined)) {
                        this.$toast.error(`Something went wrong.`);
                    }
                    // this.getTotalResultsCount = 0;
                    if (this.currentPage === 1) {
                        let totalRecords = values[0]?.data?.TotalRecords ?? 0;
                        let total = values[1]?.data?.total ?? 0;
                        this.getTotalResultsCount = totalRecords + total;
                    }
                    this.totalRecordsSize.visitedPages.push(this.currentPage);
                    // if(this.apiCallPages >= 2 || this.isFilterApplied || this.getTotalResultsCount < 100) {
                    this.isLoading = false;
                    if (this.allNews?.length > 0) {
                        this.showSearch = false;
                        this.showResult = true;
                    } else {
                        this.showSearch = true;
                        this.showResult = false;
                    }
                    // }
                    if (this.allNews?.length <= 0 && !this.isAllRunQueries) {
                        if (values[1]?.data?.status) this.$toast.error(`No Result found for ${this.searchKeyword}`);
                        else if (!values[1] && values[0]?.data?.message) this.$toast.error(values[0]?.data?.message);
                        else if (!values[1]) this.$toast.error(`No Result found for ${this.searchKeyword}`);
                    } else if (this.allNews?.length <= 0) {
                        if (values[1]?.data?.status) this.$toast.error(`No Result found`);
                        else if (!values[1] && values[0]?.data?.message) this.$toast.error(values[0]?.data?.message);
                        else if (!values[1]) this.$toast.error(`No Result found`);
                    }
                    this.allNews = this.allNews.map((ele, index) => ({...ele, curr_index: index}));
                    if (this.getAutoAnalyze && !pollingFetch) {
                        this.allNews.forEach((res) => {
                            if (res.api_all_data?.analysis_status == null) {
                                res.api_all_data.analysis_status = "pending";
                                res.api_all_data.analysis_remaining = 0;
                            }
                        });
                        await this.handleAutoAnalysis();
                        this.startQueryPolling();
                    }
                    this.filterData();
                    this.sortResults();
                    this.resetLoader("news");
                    this.$emit("getInitialQueryCount");
                    // if(this.apiCallPages == 1 && this.getTotalResultsCount > 100){
                    //     this.apiCallPages = 2;
                    //     this.manualSearch()
                    // }
                })
                .catch((error) => {
                    if (error?.response?.status === 500) {
                        this.$toast.error(`Something went wrong. Please try again.`);
                        this.isLoading = false;
                        this.showSearch = false;
                        this.showResult = true;
                        this.resetLoader("news");
                    }
                });
        },
        async handleAutoAnalysis() {
            const url = `/case-analysis/${this.$route.params.id}/analysis`;
            let sources = [];
            if (this.allSources["news"].isChecked) {
                sources.push("news");
            }
            if (this.allSources["djfactiva"].isChecked) {
                sources.push("dowjonesfactiva");
            }
            try {
                await mainAxios.post(url, {
                    record_count: this.allNews.length,
                    tool: "news-tool",
                    queries: [
                        {
                            query_id: this.request_id,
                        },
                    ],
                    config: this.getAnalyzerOption(),
                    sources: sources,
                });
                this.$toast.success("Analysis started.");
            } catch (err) {
                this.$toast.error("Something went wrong.");
            }
        },
        startQueryPolling() {
            clearInterval(this.interval);
            this.interval = setInterval(async () => {
                const url = `/case-analysis/${this.$route.params.id}/analysis/docs`;
                const {data} = await mainAxios.post(url, {doc_ids: this.allNews.map((res) => res.api_all_data.doc_id), tool: "news-tool"});
                if (data.some((res) => res.analysis_status == "completed")) {
                    this.updateCardCategories(data);
                }
                if (!data.some((res) => res.analysis_status == "pending" || !res.analysis_status)) {
                    clearInterval(this.interval);
                }
            }, 2000);
        },

        /**
         * @function
         * @name applyFilters - function to apply selected filters
         */
        async applyFilters() {
            // this.start_date.value = this.filterStartDate;
            // this.end_date.value = this.filterEndDate;
            // this.language.value = this.filterLanguages;
            await this.search();
        },

        /**
         * @function
         * @name redirectToQuery
         * @params {object} - dataRes - search filters data from the route to be autofill in the input fiels and filters
         */
        redirectToQuery(dataRes) {
            this.perPage = 100;
            //redirect to same query
            if (this.$route?.query?.query_id) {
                let {query_id} = this.$route.query;
                let selected_query = this.toolSearchQueries?.find((el) => el._id === query_id);
                let {query, sources, from_date, to_date, language, news_sources, regions, language_factiva, nearness} = selected_query?.query;

                let search_keyword = query?.split(" near")[0];
                search_keyword = search_keyword.replace("(", "");
                search_keyword = search_keyword.replace(")", "");
                this.searchKeyword = search_keyword;
                this.allSources["news"].isChecked = sources.includes("news");
                this.allSources["djfactiva"].isChecked = sources.includes("dowjonesfactiva");
                this.start_date.value = from_date ?? null;
                this.end_date.value = to_date ?? "";
                this.dowjones_sources.value = news_sources && news_sources.length > 0 ? news_sources.split(",") : [];
                this.language.value = null;
                if (language) {
                    this.language.value = this.allLanguages[0]?.categories?.find((ele) => ele.value === language) ?? {label: "English", value: "en"};
                }
                language_factiva = typeof language_factiva === "string" ? language_factiva?.split(",") : language_factiva;
                language_factiva = language_factiva?.length ? language_factiva : [];
                if (language_factiva?.length) {
                    language_factiva?.forEach((lang) => {
                        let match = this.allLanguages[0]?.categories?.find((ele) => ele.value === lang);
                        this.language_factiva.value.push(match);
                    });
                }
                regions = regions?.split(",") || [];
                if (regions?.length) {
                    regions?.forEach((reg) => {
                        let match = this.allRegions[0]?.categories?.find((ele) => ele.value === reg);
                        this.dowjones_jurisdiction.value.push(match);
                    });
                }
                this.searchKeywordStringFormatted = query;
                if (this.selectedKeywordsData) {
                    this.selectedKeywordsData.nearCount = nearness;
                    this.setSelectedKeywordsData(this.selectedKeywordsData);
                }
                this.isExistingSearchQuery = true;
                if (!dataRes) this.search();
            } else {
                this.redirectTo();
            }
        },

        /**
         * @function
         * @name fetchDownJonesSources - function to get list of all sources
         */
        async fetchDownJonesSources() {
            const {data} = await axios.get("/news/dowjones-sources");
            if (data?.length > 0) this.dowjones_sources.props.options = [{group: "Sources", categories: data}];
        },

        /**
         * @function
         * @name fetchDownJonesRegions - function to get list of all regions
         */
        async fetchDownJonesRegions() {
            let regions = [];
            await axios
                .get("/news/regions/factiva")
                .then((response) => {
                    if (response && response.data) {
                        const dowjonesRegions = response.data.regions.map((el) => ({
                            name: el.label,
                            value: el.value,
                        }));
                        regions = dowjonesRegions;
                        this.dowjones_jurisdiction.props.options = [{group: "Jurisdiction", categories: dowjonesRegions}];
                        this.allRegions = [{group: "Jurisdiction", categories: regions}];
                    }
                })
                .catch((error) => {});
        },

        /**
         * @function
         * @name openShowMorePanel - function to fetch dowjones article against a headline
         */
        async openShowMorePanel(result) {
            let data = result.result;
            this.expandedLoading = false;
            const isOldVersion = result.isOldVersion;
            if (isOldVersion) {
                this.isOldVersionSelected = true;
            } else {
                this.isOldVersionSelected = false;
            }
            let detailsExpanded = true;
            if (result.result.detailsExpanded === false) detailsExpanded = false;

            if (detailsExpanded && (this.expandedLoading || this.isSavedLoading.loading)) return;
            this.expandedLoading = true;

            if (data.key === "dowJonesFactiva") {
                if (!data.expanded || !detailsExpanded) {
                    try {
                        const article = await axios.post("/news/dowjones-article", {
                            article_ref: data.id,
                            case_id: this.getCaseId,
                            query_id: this.request_id || this.getSelectedToolQuery.query_id,
                            doc_id: data.api_all_data.doc_id,
                        });
                        if (article?.data?.data?.details) data.api_all_data = {...data.api_all_data, ...article?.data?.data?.details};
                        if (article?.data?.data?.card) {
                            data.api_all_data = {...data.api_all_data, ...article?.data?.data?.card};
                        }
                        if (article?.status === 500) {
                            this.$toast.error(`Something went wrong. Please try again.`);
                        }
                        data.expanded = true;
                        this.expandedLoading = false;
                    } catch (error) {
                        this.expandedLoading = false;
                        if (error?.response?.status === 500) this.$toast.error(`Something went wrong. Please try again.`);
                    }

                    if (data.contentType === "webpage") {
                        try {
                            const article = await axios.post("/news/dowjones-article/webpage", {
                                article_ref: data.id,
                                // case_id: this.getCaseId,
                                // query_id: this.request_id || this.getSelectedToolQuery.query_id,
                            });
                            if (article?.data?.data?.Link) {
                                data.api_all_data = {...data.api_all_data, webpage_link: article?.data?.data?.Link};
                            }
                            if (article?.data?.status === 500) {
                                this.$toast.error(`Something went wrong. Please try again.`);
                            }
                            this.expandedLoading = false;
                        } catch (error) {
                            this.expandedLoading = false;
                            if (error?.response?.status === 500) this.$toast.error(`Something went wrong. Please try again.`);
                        }
                    }
                }
                if (data.contentType === "webpage") window.open(data.api_all_data?.webpage_link);
            }
            // data.isLoading = true;
            // this.modalCompanyDetailsDataMore = company
            // data.isLoading = false;
            this.expandedLoading = false;
            document.getElementById("result-expanded")?.scrollTo({
                top: 0,
                behavior: "smooth",
            });

            setTimeout(() => {
                document.getElementById(`result-card-${result.cardIndex}`)?.scrollIntoView();
            }, 500);
            if (detailsExpanded) {
                this.isComparisonModeOn = false;
                this.showMoreInfo = true;
                this.selectedCard = data.curr_index;
                this.filteredNews[this.selectedCard].api_all_data = {...this.filteredNews[this.selectedCard].api_all_data, ...data?.api_all_data};
            } else {
                this.isComparisonModeOn = true;
                this.updateComparisonReferences(data);
            }
        },

        /**
         * @function
         * @name popOutWebpage - function to redirect to external webpage for DJFactiva - webpage type content
         */
        async popOutWebpage(data) {
            if (data.key === "dowJonesFactiva") {
                if (data.contentType === "webpage") {
                    window.open(data.api_all_data?.webpage_link);
                }
            }
        },
        handleCloseMoreInfo() {
            this.showMoreInfo = false;
            this.selectedCard = null;
            this.isOldVersionSelected = false;
        },
        limitText(length) {
            return ` +${length}`;
        },

        /**
         * @function
         * @name resetFilter - reset all search inputs and filters
         */
        resetFilter() {
            this.searchKeyword = "";
            this.isFilterApplied = false;
            this.selectedKeywordsData = {
                keywords: {},
                nearCount: null,
            };
            this.totalRecordsSize = {
                visitedPages: [],
            };
            this.setSelectedKeywordsData(this.selectedKeywordsData);
            this.setSearchWordsData([]);
            this.showFiltersPanel = false;
            for (let source in this.allSources) {
                this.allSources[source].isChecked = false;
            }
            this.order_by = {
                name: "Updated Date",
                value: "updated_at",
            };
            this.start_date.value = "";
            this.end_date.value = "";
            this.language.value = {name: "English", value: "en"};
            this.language_factiva.value = this.language_factiva?.value?.length > 0 ? uniqBy(this.language_factiva.value, "name") : [{name: "English", value: "en"}];
            this.selectedCard = null;
            this.isOldVersionSelected = false;
            this.timeline = [];
            this.$emit("updateMonitoringCascadingTimeline", {
                timeline: this.timeline,
                meta_data: null,
            });
            this.dowjones_sources.value = [];
            this.dowjones_jurisdiction.value = [];
            this.filterStartDate = "";
            this.filterEndDate = "";
            this.filterLanguages = [];
            this.filters = {
                saved: "all",
                monitoring: "all",
                sources: [],
            };
            this.showResult = false;
            this.showMoreInfo = false;
            this.showSearch = true;
            this.selectedCard = null;
            this.isResultExpanded = false;
            this.sources = [];
            this.searchQuery = "";
            this.searchKeywordStringFormatted = "";
            this.isExistingSearchQuery = false;
            this.perPage = 100;
            this.request_id = "";
            this.isAllRunQueries = false;
            this.allQueriesParams = null;
            this.offsetPag = 0;
            this.limitPag = 1;
            this.apiCallPages = 1;
            this.getTotalResultsCount = 0;
            this.redirectTo();
        },
        newKeywordsSelected(keywords) {
            this.selectedKeywordsData = keywords;
            this.setSelectedKeywordsData(keywords);
        },

        /**
         * @function
         * @name formatSearchQueryString - format search query along with keywords data
         * @params {searchText} - search query
         *         { selectedKeywordsData } - selected keywords data
         *         return the formatted search query along with keywords data
         */
        formatSearchQueryString(searchText, selectedKeywordsData) {
            if (selectedKeywordsData == null) {
                return searchText;
            }

            const keywordSets = selectedKeywordsData.keywords;
            if (keywordSets == null || Object.keys(keywordSets).length === 0) {
                return searchText;
            }

            const hasRoundBrackets = searchText.match(/^\(.*\)$/g);
            const haseSpacesInside = searchText.indexOf(" ") >= 0;
            const searchTextDecorated = hasRoundBrackets ? searchText : haseSpacesInside ? `(${searchText})` : searchText;

            const nearCount = selectedKeywordsData.nearCount;
            const nearCountString = nearCount != null ? `near${nearCount}` : "";

            const allKeywords = this.getAllKeywordsInArray(keywordSets);
            const searchKeywordsString = allKeywords.join(" OR ");
            return `${searchTextDecorated} ${nearCountString}(${searchKeywordsString})`;
        },
        getAllKeywordsInArray(keywordSets) {
            let allKeywords = [];
            Object.keys(keywordSets).forEach((key) => {
                const keywords = keywordSets[key];
                if (keywords.length > 0) {
                    allKeywords = allKeywords.concat(keywords);
                }
            });
            return allKeywords;
        },
        removeUnNeccessaryWords(txt) {
            var wordsToRemove = ["and", "or", "not"];

            var expStr = wordsToRemove.join("|");
            return txt.replace(new RegExp("\\b(" + expStr + ")\\b", "gi"), " ").replace(/\s{2,}/g, " ");
        },
        splitSearchWords(searchQuery, trimNearQuery = true) {
            if (searchQuery == null) {
                return [];
            }
            let searchWordsString = searchQuery.trim().replace(/\s+/gi, " "); // replace multiple spaces with single
            if (searchWordsString === "") {
                return [];
            }
            if (trimNearQuery) {
                searchWordsString = searchQuery.replace(/\snear[0-9]*\((.[^()]+)\)/gi, ""); // trim near query
            }
            searchWordsString = this.removeUnNeccessaryWords(searchWordsString);
            return searchWordsString.split(" ").map((w) => w.replace(/^\(|\)$/, "")); // replace "(" or ")" brackets at the beginning or at the end
        },

        /**
         * @function
         * @name handleMarkAsAllDone
         * @params {e} - pointer event for Monitoring - mark all done input checkbox
         */
        async handleMarkAsAllDone(event) {
            let visited = event.target.checked;
            let doc_id = [];
            let payload;
            // this.adverseResults.every((advRes) => advRes.api_all_data.changes_viewed);
            this.filteredNews.slice((this.currentPage - 1) * this.perPage, this.currentPage * this.perPage).forEach((_, index) => {
                let sources = [];
                sources.push(_.api_all_data.doc_source);
                payload = {
                    doc_id: _.api_all_data.monitoring_id,
                    sources: sources,
                    cascaded: _.api_all_data.history_count ? true : false,
                };
                doc_id.push(payload);
                this.filteredNews.slice((this.currentPage - 1) * this.perPage, this.currentPage * this.perPage)[index].api_all_data.changes_viewed = visited;
            });
            if (this.timeline.length > 0) {
                this.timeline = this.timeline.map((res) => {
                    let obj = res;
                    obj.api_all_data.changes_viewed = visited;
                    return obj;
                });
            }
            await SaasAxios.post("/data-monitoring/mark-monitered-data-visited", {
                // sources: this.sources,
                visited,
                card_info: doc_id,
                case_id: this.getCaseId,
            });
            this.$forceUpdate();
        },
        /**
         * @function
         * @name isSourceAuth
         * @params {String} src - selected source (news/ dow jones)
         */
        isSourceAuth(src) {
            if (src) return this.getPermissions.includes(`${src}_api__news_`) || this.getPermissions.includes(`${src}__news`);
            else return true;
        },
        /**
         * @function
         * @name handleFilterApplied
         * @params {Object} filter - selected filter
         */
        async handleFilterApplied(filter) {
            let match = -1;
            switch (filter.name) {
                case "sourceType":
                    match = this[filter.name].findIndex((el) => el.name === filter.value.name);
                    if (match > -1) this[filter.name].splice(match, 1);
                    break;
                case "filters.monitoring":
                    this.filters.monitoring = "all";
                    break;
                case "filters.saved":
                    this.filters.saved = "all";
                    break;
            }
            await this.onApplyFilter();
            // this.filterData();
            this.sortResults();
        },

        /**
         * @function
         * @name isSourceSpecificFilter
         * @params {String} src - selected source (news/dow jones)
         */
        isSourceSpecificFilter(src) {
            return this.allSources[src].filters && this.allSources[src].filters.length && this.allSources[src].filters.filter((filter) => filter.isSourceSpecific)?.length;
        },
        /**
         * @function
         * @name parseValue
         * @params {Object} value - selected filter value
         */
        parseValue(value) {
            return typeof value === "string" ? value : value.label || value.name;
        },

        /**
         * @function
         * @name sortResults
         */
        sortResults() {
            // this.filteredNews = [
            //     ...this.filteredNews
            //         .filter((el) => el[this.order_by.value])
            //         ?.sort((a, b) => {
            //             let diff = new Date(a[this.order_by.value]) - new Date(b[this.order_by.value]);
            //             if (diff > 0) return -1;
            //             else return 1;
            //         }),
            //     ...this.filteredNews.filter((el) => !el[this.order_by.value]),
            // ];
            // this.filteredNews = this.filteredNews.map((ele, index) => ({ ...ele, curr_index: index }));
        },
        async onBackToSearch() {
            this.showFiltersPanel = false;
            this.order_by = {
                name: "Updated Date",
                value: "updated_at",
            };
            this.searchQuery = "";
            this.isFilterApplied = false;
            this.filterStartDate = "";
            this.filterEndDate = "";
            this.filterLanguages = [];
            this.showResult = false;
            this.showMoreInfo = false;
            this.showSearch = true;
            this.selectedCard = null;
            this.isOldVersionSelected = false;
            this.timeline = [];
            this.$emit("updateMonitoringCascadingTimeline", {
                timeline: this.timeline,
                meta_data: null,
            });
            this.sources = [];
            this.sourceType = [];
            this.searchKeywordStringFormatted = "";
            this.isExistingSearchQuery = false;
            this.perPage = 100;
            this.request_id = "";
            this.filters = {
                saved: "all",
                monitoring: "all",
            };
            this.isAllRunQueries = false;
            this.allQueriesParams = null;
            this.offsetPag = 0;
            this.limitPag = 1;
            this.apiCallPages = 1;
            this.setPrevFilters();
            this.setToolsFilterData(this.filters);
            await this.getToolsStatus();
            this.$router.push({path: this.$route.path}).catch((err) => {});
        },
        async onApplyFilter() {
            this.currentPage = 1;
            this.apiCallPages = 1;
            this.isFilterApplied = true;
            const {monitoring, saved} = this.prevAppliedFilters;
            const {monitoring: fil_monitoring, saved: fil_saved} = this.filters;

            if (fil_monitoring != monitoring || fil_saved != saved) {
                if (this.isAllRunQueries) {
                    this.offsetPag = 0;
                    this.limitPag = 1;
                    this.isNextPag = true;
                    this.allNews = [];
                    await this.onRunAllQueries(null, "allNews");
                } else {
                    //  await this.search();
                    this.apiCallPages = 1;
                    await this.manualSearch();
                    // this is wrong approach needs to remove this later
                    if (this.getTotalResultsCount > 100 && this.perPage == 100) {
                        this.apiCallPages = 2;
                        await this.manualSearch();
                    }
                }
            }
            this.filterData();
            this.sortResults();
            this.setPrevFilters();
        },

        getFormattedTime(ts) {
            if (ts) {
                let date = new Date(ts).toISOString();
                date = DateTime.fromISO(date);
                return date.toFormat("dd MMM yyyy");
            }
            return "-";
        },
        async getNewsPagination() {
            this.apiCallPages = this.currentPage;
            if (this.filterApplied() && this.appliedFilterClicked) {
                if (this.totalFilteredPages <= 1) this.currentPage = 1;
                // await this.handleApplyFilters();
                return;
            }
            if (this.getTotalPages - 1 <= this.currentPage && (this.allNews.length <= this.perPage * this.currentPage || this.filteredNews.length <= this.perPage * this.currentPage)) {
                if (this.allNews.length < this.getTotalResultsCount || this.allNews.length <= this.totalRecordsInAllQueries) {
                    if (this.isAllRunQueries) {
                        await this.onRunAllQueries(null, "allNews");
                        // this.apiCallPages += 1;
                    } else {
                        // this.apiCallPages += 1;
                        await this.getNews();
                    }
                } else if (this.isMonitoringTab && this.latestVersionTotalResults) {
                    this.apiCallPages += 1;
                    this.manualSearch();
                }
            }
        },
        async onPerPageChange() {
            this.currentPage = 1;
            if (this.allNews.length <= this.perPage) {
                if (this.isAllRunQueries) {
                    this.allNews = [];
                    this.offsetPag = 0;
                    this.limitPag = 1;
                    await this.onRunAllQueries(null, "allNews");
                    if (this.allNews.length <= this.perPage) {
                        this.offsetPag = 1;
                        this.limitPag = 2;
                        await this.onRunAllQueries(null, "allNews");
                    }
                } else {
                    this.apiCallPages = 1;
                    await this.manualSearch();
                    // this is wrong approach needs to remove this later
                    if (this.getTotalResultsCount > 100 || this.latestVersionTotalResults > 100) {
                        this.apiCallPages = 2;
                        await this.manualSearch();
                    }
                }
            }
            if (this.appliedFilterClicked) {
                this.totalFilteredPages = this.filteredNews.length / this.perPage;
                if (this.totalFilteredPages % 2 !== 0) this.totalFilteredPages = Math.floor(this.totalFilteredPages) + 1;
            }
        },
        setPrevFilters() {
            let {monitoring, saved} = this.filters;
            this.prevAppliedFilters = {
                monitoring,
                saved,
                sourceType: this.sourceType,
                searchQuery: this.searchQuery,
            };
        },
        async onSortBy(option) {
            this.order_by = option;
            this.currentPage = 1;
            this.apiCallPages = 1;
            await this.manualSearch();
            // this is wrong approach needs to remove this later
            this.apiCallPages = 2;
            await this.manualSearch();
        },

        async showMonitoringHistory(data) {
            const payload = data.payload;
            let source = [];
            if (this.sources?.length > 0) {
                source = this.sources;
            }
            // delete payload.monitoringHistoryIndex;
            if (payload.monitoringHistoryIndex) {
                this.timeline = [];
                let values = await monitoringHistoryData(payload, source);
                if (values?.length > 0) {
                    let news = this.getMappingValue(
                        values.filter((val) => val.doc_source === "news"),
                        "news",
                        NEWS_TOOL_MAPPING,
                        "News"
                    );
                    let factiva_news = this.getMappingValue(
                        values.filter((val) => val.doc_source === "dowjonesfactiva"),
                        "dowJonesFactiva",
                        NEWS_TOOL_MAPPING,
                        "DowJones Factiva"
                    );
                    this.timeline = [...this.timeline, ...news, ...factiva_news];
                    this.timeline = this.timeline.map((ele, index) => ({...ele, curr_index: index, isOldVersion: true}));
                    // let cont = document.getElementById("result-list");
                    // if (cont) cont.scrollTop = 0;
                }
                const parent = {
                    ...data.detail,
                    is_parent: true,
                };
                const meta_data = {};
                this.$emit("updateMonitoringCascadingTimeline", {
                    timeline: cloneDeep(this.timeline)
                        .reverse()
                        .concat([parent]),
                    meta_data: meta_data,
                });
            } else {
                this.timeline = [];
                this.$emit("updateMonitoringCascadingTimeline", {
                    timeline: this.timeline,
                    meta_data: null,
                });
            }
        },

        updateComparisonReferences(details) {
            if (this.isComparisonModeOn) {
                const isOldVersion = details.isOldVersion;
                if (isOldVersion) {
                    this.$emit("updateMonitoringCascadingTimeline", {
                        newData: details,
                    });
                } else {
                    const parent = {
                        ...details,
                        is_parent: true,
                    };
                    this.$emit("updateMonitoringCascadingTimeline", {
                        newData: parent,
                    });
                }
            }
        },
    },
};
